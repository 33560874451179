<template>
  <div class="finance">
    <!--  导航栏-->
    <div class="dh">
      <div class="navigation">
        <div class="ico" @click="go()">
          <img class="icon" src="../../assets/img15.png">
          <p class="designation">华钛信息</p>
        </div>
        <div class="selectivity">
          <div class="alternative">
            <div class="xzx1 xzxz1">
              <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
              <div class="nav1 nav">
                <div class="navxx">
                  <span>行业方案</span>
                  <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                  <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzData" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img188.png">
                </div>
              </div>
            </div>
            <div class="xzx2 xzxz1">
              <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
              <div class="nav2 nav">
                <div class="navxx">
                  <span>自主产品</span>
                  <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                  <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img189.png">
                </div>
              </div>
            </div>
            <div class="xzx3 xzxz1">
              <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
              <div class="nav3 nav">
                <div class="navxx">
                  <span>服务范围</span>
                  <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                  <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img190.png">
                </div>
              </div>
            </div>
            <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
              <span class="xzxz" @click="din(item)">{{item.cname}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  第一层-->
    <div class="f-first">
      <div class="zsc">
        <div class="f-nr">
          <span>制造行业解决方案</span>
          <span>华钛信息数据科学平台为企业提供实时数据处理、实时业务、数据中台及AI建设能力，助力企业客户轻松构建智能化数据应用，完成数据智能化转型。</span>
          <span>立即咨询</span>
        </div>
        <div class="f-tp"></div>
      </div>
    </div>
    <!--  第二层-->
    <div class="f-second">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>方案优势</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="ren">
        <div class="ren1" v-for="(item, index) in faData" :key="index">
          <img :src="item.icon1">
          <span class="biao">{{item.name1}}</span>
          <span class="biao2">{{item.name2}}</span>
        </div>
      </div>
    </div>
    <!--  第三层-->
    <div class="f-thirdy">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>方案分类</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="ren2">
        <div class="ren3" v-for="(item, index) in faDatas" :key="index">
          <img :src="item.icon1">
          <div class="ren4">
            <span class="biao3">{{item.name3}}</span>
            <span class="biao4">{{item.name4}}</span>
            <div class="ul">
              <div class="li">
                <span class="dian"></span>
                <span class="sp">{{item.name5}}</span>
              </div>
              <div class="li">
                <span class="dian"></span>
                <span class="sp">{{item.name6}}</span>
              </div>
              <div class="li">
                <span class="dian"></span>
                <span class="sp">{{item.name7}}</span>
              </div>
              <div class="li">
                <span class="dian"></span>
                <span class="sp">{{item.name8}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  第四层-->
    <div class="f-four">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>解决方案</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <img class="flow" src="../../assets/img61.png">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>客户案例</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="example">
        <div class="zss">
          <div class="xj">
            <span class="b1">数据科学平台</span>
            <span class="b2">数据科学平台一个具有凝聚力的软件应用程序，围绕着机器学习，提供模块化融合的能力，既可以创建各种数据科学的解决方案，又可以将这个解决方案集成到业务流程及所涉及的周边基础设施及产品中去。</span>
            <div class="ljin" @click="mak()">
              <span>了解详情 <img src="../../assets/img217.png"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    底层-->
    <div class="ground-floor">
      <div class="fbox">
        <div class="fbox1">
          <div class="fbox2">
            <div class="lianxi">
              <div class="fico">
                <img class="ficon" src="../../assets/img2.png">
                <p class="fdesignation">华钛信息</p>
              </div>
              <div class="contway">
                <div class="special">
                  <span>联系电话：</span>
                  <span>021-55962658</span>
                </div>
                <div class="special">
                  <span>商务合作：</span>
                  <span>business@huataish.com</span>
                </div>
              </div>
            </div>
            <div class="cut-line"></div>
            <div class="explain">
              <div class="explain1">
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">行业方案</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                        <span @click="din(item)">{{ item.lei }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">自主产品</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                        <span @click="din(item)">{{ item.lei1 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">服务范围</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                        <span @click="din(item)">{{ item.lei2 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="cl()">客户案例</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                        <span>{{ item.lei3 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="hz()">合作伙伴</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                        <span>{{ item.lei3 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="xx()">华钛信息</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                        <span @click="din(item)">{{ item.lei4 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cut-line1"></div>
          <div class="safety">
            <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaKe",
  data() {
    return {
      cur: 0,
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      loopData: [
        {
          icon:require("../../assets/img17.png")
        },
        {
          icon:require("../../assets/img17.png")
        },
        {
          icon:require("../../assets/img17.png")
        },
        {
          icon:require("../../assets/img17.png")
        },
        {
          icon:require("../../assets/img17.png")
        },
        {
          icon:require("../../assets/img17.png")
        },
      ],
      foolDatas: [
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      foolDataes: [

      ],
      foolDataess: [

      ],
      lbData:[
        {
          imgg:require("../../assets/img36.png")
        },
        {
          imgg:require("../../assets/img36.png")
        },
        {
          imgg:require("../../assets/img36.png")
        },
        {
          imgg:require("../../assets/img36.png")
        },
        {
          imgg:require("../../assets/img36.png")
        },
      ],
      faData:[
        {
          icon1:require("../../assets/img215.png"),
          name1:"有效的深度学习",
          name2:"“两地六中心”合规机房，通过等保四级，可信云等认证，助力企业顺利通过合规验\n" +
              "收，数据异地自动同步。"
        },
        {
          icon1:require("../../assets/img215.1.png"),
          name1:"训练过程可视化",
          name2:"多重防护保障数据安全，秒级抵御网络攻击，P级黑产数据，90%恶意用户识率，降低金融欺诈风险。"
        },
        {
          icon1:require("../../assets/img215.2.png"),
          name1:"三位一体的建模方式",
          name2:"多渠道场景化曝光引流，连接亿万用户，个性化用户画像及社交大数据，精准营销，挖掘新业务。"
        },
        {
          icon1:require("../../assets/img215.3.png"),
          name1:"数据预处理、预准备",
          name2:"支持Oracle、金融防火墙架构，混合云部署，按需交付，轻松扩展业务，上云金融软件数十款，成功经验即刻复制。"
        },
      ],
      faDatas:[
        {
          icon1:require("../../assets/img57.png"),
          name3:"智慧工厂",
          name4:"实时计算并监控工厂数据，完成异常检测自动修正。",
          name5:"异常数据",
          name6:"品质问题",
          name7:"异常检测",
          name8:"报警系统",

        },
        {
          icon1:require("../../assets/img58.png"),
          name3:"智慧商业",
          name4:"为商业场景为环境提供基于深度学习的预测推理，适应各类场景。",
          name5:"停车场拥堵警告",
          name6:"厨房明火检测",
          name7:"工地车辆监控",
          name8:"会议室能源控制",
        },
        {
          icon1:require("../../assets/img59.png"),
          name3:"数字人像",
          name4:"全新的交互方式触达客户，旨在利用AI提高服务效率、提升客户满意度。",
          name5:"客户身份验证",
          name6:"电子签章",
          name7:"产品推荐",
          name8:"产品交易",

        },
        {
          icon1:require("../../assets/img60.png"),
          name3:"智能客车",
          name4:"对时序数据预测与异常检测，自动生成异常时间点。",
          name5:"异常检测",
          name6:"深度学习",
          name7:"智能监测",
          name8:"识别异常",
        },
      ],
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    mak(){
      window.open('/database','_blank')

    },
    hz(){
      window.open('/Partner','_blank')
    },
    xx(){
      window.open('/information','_blank')
    },
    cl(){
      window.open('/Clien','_blank')
    }
  }

}
</script>

<style scoped>
body{
  overflow-anchor: none;
  background-color: #eee;
}
.zsc{
  width: 1180px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
body:after {
  content: '\20';
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
  clear: both;
}
.finance{
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  min-width: 1230px;
  position: relative;
  width: 100%;
}
/*标题*/
.spreadhead {
  width: 100%;
  height: 100px;
  margin-bottom: 48px;
  /*background: red;*/
}
.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}
.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 272px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}
.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}
.spreadhead .subhead .moit span {
  display: block;
  width: 96px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}
.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}
/*第一层*/
.f-first{
  width: 100%;
  height: 526px;
  background-image: url("../../assets/img56.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.f-first .zsc{
  display: flex;
  justify-content: space-between;
}
.f-first .zsc .f-nr{
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 648px;
  height: 212px;
  position: relative;
  top: 188px;
}
.f-first .zsc .f-nr span:nth-child(1){
  height: 59px;
  font-size: 42px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 59px;
}
.f-first .zsc .f-nr span:nth-child(2){
  height: 68px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 34px;
}
.f-first .zsc .f-nr span:nth-child(3){
  width: 103px;
  height: 32px;
  background: #2A5CFF;
  box-shadow: 0px 2px 10px 0px rgba(9, 55, 204, 0.31);
  text-align: center;
  line-height: 32px;
  color: #FFFFFF;
}
.f-first .zsc .f-tp{
  display: inline-block;
  width: 328px;
  height: 324px;
  background-image: url("../../assets/img207.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 116px;
}
/*第二层*/
.f-second{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 390px;
}
.f-second .ren{
  display: flex;
  justify-content: space-between;
  width: 1154px;
  height: 180px;
  margin: 0 auto;
}
.f-second .ren .ren1{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 254px;
  height: 180px;
}
.f-second .ren .ren1 img{
  display: block;
  width: 48px;
  height: 48px;
}
.f-second .ren .ren1 .biao{
  /*width: 180px;*/
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 28px;
}
.f-second .ren .ren1 .biao2{
  width: 254px;
  height: 60px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 20px;
}
/*.第三层*/
.f-thirdy{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 586px;
  background-image: url("../../assets/img48.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.f-thirdy .ren2{
  width: 1180px;
  height: 368px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}
.f-thirdy .ren2 .ren3{
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 368px;
  box-shadow: 3px 3px 16px 0px #BFCBE1;
}
.f-thirdy .ren2 .ren3 .ren4{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 32px 20px 24px;
  background-color: white;
}
.f-thirdy .ren2 .ren3 .ren4 .biao3{
  width: 88px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 28px;
  margin-bottom: 12px;
}
.f-thirdy .ren2 .ren3 .ren4 .biao4{
  width: 224px;
  height: 78px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 26px;
  margin-bottom: 12px;
}
.f-thirdy .ren2 .ren3 .ren4 .ul{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.f-thirdy .ren2 .ren3 .ren4 .ul .li{
  width: 136px;
  height: 20px;
  display: flex;
  justify-content: space-between;
}
.f-thirdy .ren2 .ren3 .ren4 .ul .li .dian{
  width: 4px;
  height: 4px;
  background: #605F5F;
  margin-top: 8px;
  border-radius: 50%;
}
.f-thirdy .ren2 .ren3 .ren4 .ul .li .sp{
  width: 122px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 20px;
}
/*第四层*/
.f-four{
  display: flex;
  flex-direction: column;
  width: 100%;
  /*height: 1147px;*/
}
.f-four .flow{
  display: block;
  margin: 0 auto;
}
.f-four .example{
  background-image: url("../../assets/img208.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  width: 100%;
  height: 280px;
  background-color: #1d2437;
  position: relative;
}
.f-four .example .zss{
  position: relative;
}
.f-four .example .im{
  width: 600px;
  height: 280px;
}
.f-four .example .xj{
  position: absolute;
  right: -10%;  display: block;
  flex-direction: column;
  width: 840px;
  height: 280px;
}
.f-four .example .xj .b1{
  display: block;
  margin-top: 50px;
  margin-left: 42px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 40px;
}
.f-four .example .xj .b2{
  display: block;
  margin-top: 24px;
  margin-left: 42px;
  width: 728px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
}
.f-four .example .xj .ljin{
  width: 86px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #124BFF;
  line-height: 22px;
  margin-top: 24px;
  margin-left: 42px;
}

.f-four .example .bj16{
  background-image: url("../../assets/img55.png");
  background-repeat: no-repeat;
  flex: 1;
  background-position-y: bottom;
}

</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}
.el-carousel__item img {
  width: 100%;
  line-height: 200px;
  margin: 0;
}
.el-carousel__arrow--left{
  border: 1px solid #124BFF;
}
.el-carousel__arrow--right{
  border: 1px solid #124BFF;
}
.el-carousel__arrow i{
  color: white;
}
</style>